#SiteHeader.scrolling{
  .header-scrolling{
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    height: 70px;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
    .menu{
      top: 25px;
    }
    .logo{
      top: 10px;
      & > img{
        width: 90px;
      }
    }
  }

}


.desktop.accessibility_wrapper.scrolling{
  top: 75px;
}
