

@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 300;
  src: url("fonts/opensans/opensanshebrew-lightitalic-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-lightitalic-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-lightitalic-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-lightitalic-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  src: url("fonts/opensans/opensanshebrew-light-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-light-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-light-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-light-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 400;
  src: url("fonts/opensans/opensanshebrew-italic-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-italic-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-italic-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-italic-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/opensans/opensanshebrew-regular-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-regular-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-regular-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-regular-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 700;
  src: url("fonts/opensans/opensanshebrew-bolditalic-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-bolditalic-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-bolditalic-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-bolditalic-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  src: url("fonts/opensans/opensanshebrew-bold-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-bold-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-bold-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-bold-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 800;
  src: url("fonts/opensans/opensanshebrew-extrabold-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-extrabold-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-extrabold-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-extrabold-webfont.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 800;
  src: url("fonts/opensans/opensanshebrew-extrabold-webfont.eot");
  src: url("fonts/opensans/opensanshebrew-extrabold-webfont.eot?#iefix") format('embedded-opentype'), url("fonts/opensans/opensanshebrew-extrabold-webfont.woff") format('woff'), url("fonts/opensans/opensanshebrew-extrabold-webfont.ttf") format('truetype');
}

body {
  margin: 0;
  direction: ltr;
  background: #ffffff;
}

#SiteLayout {
  direction: rtl;
}

#SiteMobileMenu {
  direction: rtl;
  display: none;
}

body,
div,
h1,
input,
select,
span,
textarea {
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Open Sans Hebrew', 'Open Sans', sans-serif;
  color: #000000;
}

p {
  margin: 0 0 20px;
}

.row3 h1 {
  color: #fff;
  font-size: 40px;
}
/* ----------------------------------------------- */
@-webkit-keyframes animated_cs_logo {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 -418px;
  }
}
@keyframes animated_cs_logo {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 -418px;
  }
}

.cs_logo_png {
  margin: 0 auto;
  width: 163px;
  height: 22px;
  background: url('../images/simplysmart.png') no-repeat 0 0;
  -webkit-animation: animated_cs_logo 3s steps(19) infinite;
  animation: animated_cs_logo 3s steps(19) infinite;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cs_logo_png_b {
  margin: 0 auto;
  width: 163px;
  height: 22px;
  background: url('../images/simplysmart-b.png') no-repeat 0 0;
  -webkit-animation: animated_cs_logo 3s steps(19) infinite;
  animation: animated_cs_logo 3s steps(19) infinite;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
/* ----------------------------------------------- */
.SiteWidth {
  max-width: 1200px;
  margin: 0 auto;
}

#SiteLayout {}
/* ----------------------------------------------- */
#SiteHeader {
  position: relative;
}

#SiteHeader > .wrap {
  position: relative;
  padding-bottom: 0;
}

#SiteHeader .logo {
  position: absolute;
  display: block;
  left: 40px;
  top: 0px;
  @include respond-to('huge'){
    left: 10px;
  }
  @include respond-to('large'){
    top: -30px;
  }
  @include respond-to('medium'){
    top: -15px;
  }
}

#SiteHeader .menu {
  font-size: 0;
  position: absolute;
  display: block;
  top: 50px;
  right: 40px;

  @include respond-to('huge'){
    right: 10px;
  }
  @include respond-to('large'){
    top: 60px;
  }
}

#SiteHeader .menu > .item {
  font-size: 18px;
  padding: 0 15px;
  display: inline-block;
  text-decoration: none;
  border-left: 1px solid #D1D1D1;
  border-right: 1px solid white;
  color: black;
  @include respond-to ('huge') {
    font-size: 16px;
  }
  @include respond-to ('larger'){
    padding: 0 10px;
  }
}

#SiteHeader .menu > .item.selected {
  color: #E83F38;
}

#SiteHeader .menu > .item:hover {
  color: #E83F38;
}

#SiteHeader .menu > .item:first-child {
  border-right: none;
}

#SiteHeader .menu > .item:last-child {
  border-left: none;
}

#SiteHeader .menu > .item span {
  font-size: 18px;
}

#SiteHeader .menu > .item .tel {
  color: #E83F38;
}

#SiteHeader .menu > .item .icon {
  position: relative;
  top: 5px;
  vertical-align: top;
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url("../images/phone-red.png") center center no-repeat;
}

#SiteHeader .menu > .item .icon {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAARCAYAAAAG/yacAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABAklEQVR4nI3SsSvFURjG8V9SNiWZvhY9y5UymFitYpHJIos/wKL7NyiTlFkSM0UWiz+A6Y6GZyAlSbrIsby3Ttf1+53h1On0fs4573NOlVKq6obFqMW5xWpvrRYE2rVIFpcW7UZk0bL4tPiwOAjcakLXUXho8RrzjSZ0ZfFgcRogWWyV9DRj0c3QSgkasngJ8GMxUYKWslNuStIbsehkaL0E7WSgYzFciyymLN4ytPbnR1hsW9xbLFiMWdxl4CLfsAeIXb8t9voifrKYHIROouDY4jEDXYvF/qtXFvNR8GxxlIF3i+VB/VYW4xZnFvsWXwFuLWb/CykPYtpi02Ku6cF/AUT6IniEsExSAAAAAElFTkSuQmCC');
}

#SiteHeader .texts::before {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 350px;
  content: ' ';
  background-image: linear-gradient(to top, rgba(0,0,0,.7) 0, rgba(0,0,0,.0) 100%);
}

#SiteHeader .texts {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#SiteHeader .texts-wrap {
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px 50px;
}

#SiteHeader .texts .text1 {
  color: white;
  font-size: 28px;
}

#SiteHeader .texts .text2 {
  color: white;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 30px;
}

#SiteHeader .texts .form {
  display: inline-block;
  background: #E9E9E9;
  padding: 5px;
}

#SiteHeader .texts .form-wrap {
  background: black;
  border-radius: 3px;
  padding: 5px;
  font-size: 0;
}

#SiteHeader .texts .input {
  text-indent: 15px;
  color: white;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  height: 38px;
  border: none;
  width: 260px;
  background: transparent;
}

#SiteHeader .texts .button {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  padding: 0 45px 0 15px;
  height: 38px;
  background: white url("../images/phone-btn.png") right top no-repeat;
  border-radius: 3px;
  color: #E5231B;
  font-weight: bold;
  border: none;
}

#SiteHeader .texts .button {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAmCAMAAADkx9tQAAAAclBMVEXlIxv////60tHsWlT//Pz2srDzlZHxhIDvdnHtZ2LsX1npQjvnLyj+9vb+9fT97ez72tn4v733uLb1q6jzm5fyjYnwfnnubGf++Pj97+/86en73975y8n3urf0pKD0oZ7xhYHtZV/rVlDoOjPoOTLmKSEUR7//AAAAgklEQVQ4y+3LRxLDIBAAwR1QAIRycs7+/xf9Adblg4+aa9fI1l/yQ/VF44Fx1XmivdRR0yqj2OE1HjE9LOp9LgAnaiXw0HmB+q2zgVxXB1hVYwtGn69ASMrqREIGQ1KfDfPcwP6V5JxjDnBPqoUCoJRkJ3oDmaISugk6K2r+ZmXrpz6ktQRSEiBhPAAAAABJRU5ErkJggg==');
}

#SiteHeader .strip {
  height: 450px;
  background-position: center center;
  background-size: cover;
  box-shadow: inset 0 230px 190px -90px rgba(255,255,255,1);
}
@media all and (max-width: 1100px) {
  #SiteHeader .strip {
    height: 160px;
  }
}

#SiteHeader .slides {
  height: 650px;
  position: relative;
}

#SiteHeader .slides .slide {
  display: none;
  box-shadow: inset 0 230px 190px -90px rgba(255,255,255,1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
}

#SiteHeader .slides .slide:first-child {
  display: block;
}
@media all and (max-width: 992px) {
  #SiteHeader .menu {
    display: none;
  }

  #SiteHeader .logo {
    left: 80px;
    right: 80px;
    text-align: center;
  }

  #SiteHeader .logo img {
    max-width: 100%;
  }

  #SiteHeader .texts .text1 {
    font-size: 14px;
  }

  #SiteHeader .texts .text2 {
    font-size: 16px;
    padding-bottom: 30px;
  }

  #SiteHeader .texts .input {
    width: 120px;
  }

  #SiteHeader .mobile-bars {
    display: block;
    z-index: 1010;
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    background: url("../images/mobile-bars.png") center center no-repeat;
  }

  #SiteHeader .mobile-call {
    display: block;
    z-index: 1010;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    background: url("../images/mobile-callus.png") center center no-repeat;
  }

  #SiteLayout {
    transition: transform 0.5s;
    overflow: hidden;
    position: relative;
  }

  #SiteMobileMenu {
    transform: translate(110%);
    z-index: 99999999999;
    position: fixed;
    background: #E5231B;
    top: 0;
    left: auto;
    width: 72%;
    bottom: -100px;
    height: auto;
    right: 0;
    margin-left: auto;
    padding-bottom: 100px;
    display: block;
    -webkit-box-shadow: -3px 0 5px 0 rgba(0,0,0,0.25);
    -moz-box-shadow: -3px 0 5px 0 rgba(0,0,0,0.25);
    box-shadow: -3px 0 5px 0 rgba(0,0,0,0.25);
    overflow: auto;
    transition: transform 0.5s;
  }

  body.ShowSiteMenu {
    overflow: hidden;
  }

  body.ShowSiteMenu #SiteLayout {
    transform: translate(-72%);
  }

  body.ShowSiteMenu #SiteMobileMenu {
    transform: translate(0%);
  }

  #SiteMobileMenu .item {
    border-bottom: 1px solid rgba(255,255,255,0.15);
    font-weight: bold;
    font-size: 18px;
    color: rgba(255,255,255,0.9);
    text-decoration: none;
    display: block;
    padding: 15px 20px;
  }

  #SiteMobileMenu .item.selected {
    color: rgba(255,255,255,0.95);
  }

  #SiteMobileMenu .item span {
    font-weight: bold;
    font-size: 18px;
    color: rgba(255,255,255,0.9);
  }
}

#SiteFooter {
  position: relative;
  background: #181818;
}

#SiteFooter .row1 {
  display: table;
  width: 100%;
  border-bottom: 1px solid black;
  padding: 95px 0 55px;
}

#SiteFooter .row1 .info {
  font-size: 0;
  display: table-cell;
  width: 340px;
  vertical-align: top;
}

#SiteFooter .row1 .links {
  font-size: 0;
  display: table-cell;
  vertical-align: top;
}

#SiteFooter .row1 .form {
  display: table-cell;
  vertical-align: top;
  width: 400px;
}

#SiteFooter .row2 {
  border-top: 1px solid #313131;
  padding: 45px 0 60px;
}

#SiteFooter .row1 .info p {
  color: white;
}

#SiteFooter .row1 .info span {
  color: white;
}

#SiteFooter .row1 .links a {
  font-size: 14px;
  color: #777777;
  text-decoration: none;
  width: 50%;
  display: inline-block;
  margin-bottom: 20px;
}

#SiteFooter .row1 .links a:hover {
  color: #ffffff;
}

#SiteFooter .row1 .form .note {
  color: #E5231B;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
}

#SiteFooter .row1 .form .inputs {
  display: table;
  width: 100%;
}

#SiteFooter .row1 .form .inputs .texts {
  display: table-cell;
  vertical-align: top;
}

#SiteFooter .row1 .form .inputs .texts .text {
  color: black;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  border: none;
  background: #ffffff;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
}

#SiteFooter .row1 .form .inputs .areas {
  display: table-cell;
  vertical-align: top;
  width: 60%;
  padding-right: 15px;
}

#SiteFooter .row1 .form .inputs .areas .area {
  color: black;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  border: none;
  background: #ffffff;
  height: 150px;
  border-radius: 5px;
  padding: 10px;
}

#SiteFooter .row1 .form .buttons {
  text-align: left;
}

#SiteFooter .row1 .form .buttons .button {
  border: none;
  background: #E5231B;
  border-radius: 5px;
  color: white;
  height: 35px;
  padding: 0 20px;
}
@media all and (max-width: 1200px) {
  #SiteFooter > .wrap {
    padding: 0 20px;
  }

  #SiteFooter .row1 .links a {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  #SiteFooter .row1 .info {
    display: block;
    max-width: 400px;
    width: 100%;
    margin: 0 auto 40px;
  }

  #SiteFooter .row1 .links {
    display: block;
    max-width: 400px;
    margin: 0 auto 20px;
  }

  #SiteFooter .row1 .form {
    display: block;
    width: auto;
    max-width: 400px;
    margin: 0 auto;
  }

  #SiteFooter .row1 .links a {
    width: 100%;
  }
}
@media all and (max-width: 590px) {
  #SiteFooter .row1 .form .inputs {
    display: block;
  }

  #SiteFooter .row1 .form .inputs .texts {
    display: block;
  }

  #SiteFooter .row1 .form .inputs .areas {
    display: block;
    width: auto;
    padding-right: 0;
  }
}

#SiteBody {
  position: relative;
  background-color: #fff;
}

#SiteBody > .wrap > .cols {
  display: table;
  width: 100%;
}

#SiteBody > .wrap > .cols > .col1 {
  display: table-cell;
  vertical-align: top;
  width: 270px;
  padding: 40px 0 0 40px;
  box-shadow: inset 100px 0 150px -90px rgba(226,226,226,1);
}

#SiteBody > .wrap > .cols > .col2 {
  display: table-cell;
  vertical-align: top;
  border-right: 1px solid #E2E2E2;
}

#SiteBody .page-title {
  margin: 0;
  padding: 40px;
  display: block;
  font-size: 24px;
  font-weight: normal;
  color: #E7322A;
  border-bottom: 1px solid #E2E2E2;
}

#SiteBody .page-text {}

#SiteBody .page-text img {
  max-width: 100%;
}

#SiteBody .page-text .content {
  padding: 40px;
  @include respond-to('medium'){
    padding: 0;
  }
}

#SiteBody .page-toolbar {
  padding: 40px 40px 40px 0;
  border-top: 1px solid #E2E2E2;
}

#SiteBody .page-toolbar .FacebookWrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 4px;
  max-width: 250px;
  overflow: hidden;
}

#SiteBody .page-toolbar .GoogleWrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 1px;
  margin-right: 30px;
}

#SiteBody .page-toolbar .TweeterWrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 3px;
  margin-left: 10px;
}

#SiteBody .page-toolbar .OtherActions {
  float: left;
  text-align: left;
}

#SiteBody .page-toolbar .OtherActions a {
  text-decoration: none;
  display: inline-block;
  margin-right: 30px;
  color: black;
}

#SiteBody .page-toolbar .OtherActions a img {
  margin-left: 10px;
  vertical-align: bottom;
}

#SiteBody .side-menu {
  box-shadow: 0 0 20px 0 rgba(218,218,218,1);
  margin-bottom: 40px;
  border: 1px solid #E2E2E2;
  border-radius: 3px;
  background: white;
}

#SiteBody .side-menu a {
  display: block;
  border-bottom: 1px solid #E2E2E2;
  text-decoration: none;
  color: black;
  padding: 20px;
  font-size: 16px;
}

#SiteBody .side-menu a:hover {
  color: #E5231B;
}

#SiteBody .side-menu a.selected {
  color: #E5231B;
}

#SiteBody .side-menu a:last-child {
  border-bottom: none;
}

#SiteBody .side-form {
  margin-bottom: 40px;
}

#SiteBody .side-form .title {
  color: #000000;
  border-top: 1px solid #D7D7D7;
  font-size: 18px;
  padding: 30px 0;
}

#SiteBody .side-form .body {
  box-shadow: 0 0 20px 0 rgba(218,218,218,1);
  border: 1px solid #E2E2E2;
  border-radius: 3px;
  padding: 20px;
  background: white;
}

#SiteBody .side-form .body > .text {
  margin-bottom: 20px;
  color: #E5231B;
}

#SiteBody .side-form .body > form .text {
  height: 35px;
  text-indent: 10px;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #C3C3C3;
}

#SiteBody .side-form .body > form .area {
  height: 60px;
  text-indent: 10px;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #C3C3C3;
}

#SiteBody .side-form .body > form .button {
  display: block;
  width: 100%;
  border: none;
  color: white;
  background: #E5231B;
  height: 40px;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  #SiteBody .page-toolbar {
    padding-left: 40px;
  }
}
@media all and (max-width: 980px) {
  #SiteBody > .wrap > .cols > .col1 {
    display: none;
  }

  #SiteBody .page-toolbar {
    text-align: center;
  }

  #SiteBody .page-toolbar .FacebookWrapper {
    display: block;
    margin-bottom: 20px;
  }

  #SiteBody .page-toolbar .OtherActions {
    margin-top: 20px;
    float: none;
    text-align: center;
    display: block;
  }
}
/* ----------------------------------------------- */
.customer {}

.customer .title {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  cursor: pointer;
  padding: 10px;
  background: white;
  font-size: 0;
  position: relative;
}

.customer .title .arrow {
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  background: url("../images/close.png") center center no-repeat;
  width: 21px;
  height: 21px;
}

.customer .title .name {
  margin-left: 10px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
  display: inline-block;
}

.customer .title .date {
  color: #777777;
  padding-right: 10px;
  border-right: 1px solid #777777;
  vertical-align: middle;
  display: inline-block;
}

.customer .title .letter {
  position: absolute;
  left: 10px;
  top: 10px;
  vertical-align: middle;
  display: inline-block;
  background: url("../images/email.png") center center no-repeat;
  width: 28px;
  height: 20px;
}

.customer .body {
  display: none;
  width: 100%;
  background: #F5F5F5;
  padding: 25px 35px;
  box-shadow: inset 0 0 20px 0 rgba(0,0,0,0.25);
}

.customer .body .text {
  vertical-align: top;
  display: table-cell;
  color: black;
}

.customer .body .letter {
  vertical-align: top;
  display: table-cell;
  width: 155px;
  border-right: 1px solid #D5D5D5;
  padding-right: 40px;
}

.customer .body .letter div {
  background: #E5231B;
  border-radius: 3px;
  color: white;
  padding: 25px 35px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.customer.opened .title .arrow {
  background: url("../images/open.png") center center no-repeat;
}

.customer.opened .title .name {
  color: #E5231B;
}

.customer.opened .body {
  display: table;
}

.letter-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.75);
}

.letter-mask .frame {
  position: absolute;
  left: 50%;
  top: 50%;
  background: white;
  border: 5px solid black;
}

.letter-mask .frame img {
  width: 100%;
  height: 100%;
}

.letter-mask .frame .close {
  border-radius: 0 0 8px 8px;
  position: absolute;
  right: 8px;
  top: 0;
  color: white;
  background: black;
  padding: 0 8px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
/* ----------------------------------------------- */
.form-cols {
  width: 100%;
  display: table;
  margin-top: 50px;
  margin-bottom: 50px;
}

.form-cols .form-col1 {
  width: 50%;
  display: table-cell;
  vertical-align: top;
  padding-left: 50px;
  border-left: 1px solid #E2E2E2;
}

.form-cols .form-col2 {
  width: 50%;
  display: table-cell;
  vertical-align: top;
  padding-right: 50px;
}

.form-cols .form-inner {
  background: white;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
  border-radius: 3px;
  padding: 50px;
}

.form-cols .form-col1 .title {
  color: black;
  font-size: 20px;
  margin-bottom: 35px;
}

.form-cols .form-col1 .row {
  position: relative;
  margin-bottom: 10px;
}

.form-cols .form-col1 .row:last-child {
  margin-bottom: 0;
}

.form-cols .form-col1 .row input,
.form-cols .form-col1 .row select {
  height: 40px;
  padding: 0 20px;
  display: block;
  width: 100%;
  border-radius: 3px;
  color: black;
  border: 1px solid #E2E2E2;
}

.form-cols .form-col1 .row input[type="checkbox"] {
  height: 20px;
  padding: 0 20px;
  display: inline-block;
  width: 20px;
  border-radius: 3px;
  color: black;
  border: 1px solid #E2E2E2;
  vertical-align: middle;
}

.form-cols .form-col1 .row.warp_checkbox {
  height: 40px;
  padding: 3px 0;
}

.form-cols .form-col1 .calicon {
  cursor: pointer;
  position: absolute;
  left: 6px;
  top: 6px;
  background: url("../images/date.png");
  width: 37px;
  height: 27px;
  display: block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAbAgMAAACGgK5NAAAACVBMVEUAAADIyMjlIxuPobwsAAAAAXRSTlMAQObYZgAAACBJREFUGNNjCA1hDXUAE1RgEgIQtatWAAkqMAkBqvoNAHDJOZ7TI81MAAAAAElFTkSuQmCC');
}

.form-cols .form-col1 .row textarea {
  height: 90px;
  padding: 5px 20px;
  display: block;
  width: 100%;
  border-radius: 3px;
  color: black;
  border: 1px solid #E2E2E2;
}

.form-cols .form-col1 .row textarea.big {
  height: 190px;
}

.form-cols .form-col2 .title {
  color: black;
  font-size: 20px;
  margin-bottom: 35px;
}

.form-cols .form-col2 .row {
  margin-bottom: 10px;
}

.form-cols .form-col2 .row input.form_inp {
  height: 40px;
  padding: 0 20px;
  display: block;
  width: 100%;
  border-radius: 3px;
  color: black;
  border: 1px solid #E2E2E2;
}

.form-cols .form-col2 .button {
  margin-top: 20px;
}

.form-cols .form-col2 .button input {
  display: block;
  width: 100%;
  border: none;
  height: 70px;
  background: #E5231B;
  border-radius: 3px;
  color: white;
  font-size: 18px;
}

.form-cols .form-col2 .row .ui-spinner {
  float: left;
}

.form-cols .form-col2 .row .spinner {
  width: 40px;
}

.form-cols .form-col2 .row .warp_options {
  border: 1px solid #E2E2E2;
  border-radius: 3px;
}

.form-cols .form-col2 .row .options_title {
  position: relative;
  margin: 0;
  padding: 8px 16px;
  background: url('../images/ddl.png') no-repeat 5px center;
  cursor: pointer;
}

.form-cols .form-col2 .row .options_list {
  display: none;
  position: absolute;
  z-index: 4;
  right: 0;
  left: 0;
  padding: 10px 6px 0;
  background: #fff;
  border: 1px solid #E2E2E2;
  box-shadow: 0 4px 6px rgba(0,0,0,.2);
  border-radius: 0 0 4px 4px;
}

.form-cols .form-col2 .row .options_list.open {
  display: block;
}

.warp_options_title {
  display: block;
}

.form-cols .form-col2 .row .ui-selectmenu-button {
  width: 100% !important;
  background: white;
  height: 40px;
  display: block;
  border-radius: 3px;
  color: black;
  border: 1px solid #E2E2E2;
}

.form-cols .form-col2 .row .ui-selectmenu-text {
  text-align: right;
  padding: 0 20px;
  line-height: 40px;
}

.form-cols .form-col2 .row .ui-selectmenu-button .ui-icon {
  background: url("../images/ddl.png");
  width: 28px;
  height: 28px;
  margin: 0;
  right: auto;
  left: 5px;
  top: 5px;
}

.form-cols .form-col2 .row .options_title,
.form-cols .form-col2 .row .ui-selectmenu-button .ui-icon {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcBAMAAACAI8KnAAAAKlBMVEXlIxvlIxvlIxvlIxvlIxvlIxv////vdnHqTEb98fH61tT84+LoPzjnMSl1RmY5AAAABXRSTlOR7QbuiVoMCKAAAABpSURBVBjTY1AQDYWDYCYG1lAkEMIgiswNZAhFAXTlRu0AsbqXQrlhma2hoRHTUqHc8LQZoaGdaaUwvWWZRyOmpUP0gqXXAiURJpdlASUhXIg0SBJhb1k6ijMiWqnuI7SARQ92BVPkSAEAr3WEyxrOe1QAAAAASUVORK5CYII=');
}

.ui-selectmenu-menu .ui-menu-item {
  text-align: right;
}
@media all and (max-width: 1200px) {
  .form-cols {
    display: block;
  }

  .form-cols .form-col1 {
    display: block;
    margin: 0 50px 50px;
    width: auto;
    padding-left: 0;
    border-left: none;
  }

  .form-cols .form-col2 {
    display: block;
    margin: 0 50px;
    width: auto;
    padding-right: 0;
  }
}
@media all and (max-width: 590px) {
  .form-cols {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form-cols .form-col1 {
    margin: 0 20px 20px;
  }

  .form-cols .form-col2 {
    margin: 0 20px;
  }

  .form-cols .form-inner {
    padding: 0;
    box-shadow: none;
  }

  .warp_fields > table > tbody > tr > td {
    display: block;
  }
}
/* ----------------------------------------------- */
.homepage {
  padding: 15px 0 0;
}

.homepage .row1 {
  padding-bottom: 15px;
}

.homepage .row1 > .inner {
  display: table;
  width: 100%;
}

.homepage .row1 .col1 {
  width: 33.33%;
  padding-left: 10px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row1 .col1 > .inner {
  background: url("../images/home-image-1.jpg") center center no-repeat;
  background-size: cover;
  height: 250px;
  display: block;
  text-decoration: none;
}

.homepage .row1 .col2 {
  width: 33.33%;
  padding-left: 5px;
  padding-right: 5px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row1 .col2 > .inner {
  background: url("../images/home-image-2.jpg") center center no-repeat;
  background-size: cover;
  height: 250px;
  display: block;
  text-decoration: none;
}

.homepage .row1 .col3 {
  width: 33.33%;
  padding-right: 10px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row1 .col3 > .inner {
  background: url("../images/home-image-3.jpg") center center no-repeat;
  background-size: cover;
  height: 250px;
  display: block;
  text-decoration: none;
}

.homepage .row1 .inner {
  transition: all 0.5s;
}

#SiteLayout .homepage .row1 .inner {
  background-size: 100% 100%;
}

#SiteLayout .homepage .row1 .inner:hover {
  background-size: 120% 120%;
}

.homepage .row1 .inner {
  position: relative;
}

.homepage .row1 .inner .title {
  padding-top: 100px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 24px;
  display: block;
}

.homepage .row1 .inner .text {
  text-align: center;
  color: white;
  font-size: 16px;
  display: block;
}

.homepage .row1 .inner .more {
  background: url("../images/more.png");
  width: 28px;
  height: 28px;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.homepage .row2 {
  padding-bottom: 15px;
}

.homepage .row2 > .inner {
  display: table;
  width: 100%;
}

.homepage .row2 .col1 {
  width: 33.33%;
  padding-left: 10px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row2 .col1 > .inner {
  background: url("../images/home-image-4.jpg") center center no-repeat;
  background-size: cover;
  height: 440px;
  display: block;
  text-decoration: none;
}

.homepage .row2 .col2 {
  width: 66.66%;
  padding-right: 5px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row2 .col2 > .inner {
  background: url("../images/home-image-5.jpg") center center no-repeat;
  background-size: cover;
  height: 440px;
  display: block;
  text-decoration: none;
}

.homepage .row2 .col1 > .inner {}

.homepage .row2 .col1 > .inner .wrapper {
  margin: 0 auto;
  display: block;
  max-width: 210px;
  padding-top: 330px;
}

.homepage .row2 .col1 > .inner .text {
  display: block;
  font-size: 16px;
}

.homepage .row2 .col1 > .inner .button {
  line-height: 53px;
  font-size: 26px;
  height: 53px;
  padding: 0 20px;
  display: block;
  background: #E5231B;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.homepage .row2 .col2 .inner {
  text-align: center;
}

.homepage .row2 .col2 .reviews {
  height: 360px;
  position: relative;
}

.homepage .row2 .col2 .reviews .review {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.homepage .row2 .col2 .reviews .review:first-child {
  display: block;
}

.homepage .row2 .col2 .reviews .review .title {
  color: white;
  font-weight: bold;
  font-size: 32px;
  padding-top: 60px;
  padding-bottom: 40px;
}

.homepage .row2 .col2 .reviews .review .stars {
  height: 20px;
  background: url("../images/stars.png") center center no-repeat;
}

.homepage .row2 .col2 .reviews .review .text {
  font-size: 18px;
  color: white;
  padding-top: 40px;
  max-width: 400px;
  margin: 0 auto;
}

.homepage .row2 .col2 .reviews .review .name {
  font-size: 18px;
  color: white;
  padding-top: 25px;
}

.homepage .row2 .col2 .nav {
  background: white;
  padding: 1px;
  display: inline-block;
}

.homepage .row2 .col2 .nav span {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.homepage .row2 .col2 .nav .next {
  background: #E5231B;
  color: white;
}

.homepage .row2 .col2 .nav .back {
  background: white;
  color: #E5231B;
}

.homepage .row3 {
  padding-bottom: 15px;
}

.homepage .row3 > .inner {
  padding: 75px 0;
  display: table;
  width: 100%;
  background: url("../images/home-image-6.jpg") center center no-repeat;
  background-size: cover;
}

.homepage .row3 .col1 {
  width: 33.33%;
  padding-left: 10px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row3 .col1 > .inner {
  display: block;
  text-decoration: none;
}

.homepage .row3 .col2 {
  width: 66.66%;
  padding-right: 5px;
  display: table-cell;
  vertical-align: top;
}

.homepage .row3 .col2 > .inner {
  display: block;
  text-decoration: none;
}

.homepage .row3 .col1 {
  border-left: 2px solid white;
  text-align: center;
  padding: 25px 100px;
}

.homepage .row3 .col1 .text {
  color: white;
  font-size: 32px;
  padding-top: 50px;
  margin: 0;
}

.homepage .row3 .col2 .inner {
  color: white;
  padding: 0 65px;
  font-size: 16px;
}
@media all and (max-width: 1200px) {
  .homepage .row1 > .inner {
    display: block;
    width: 100%;
  }

  .homepage .row1 .col1 {
    width: 100%;
    padding: 0 15px 15px;
    display: block;
  }

  .homepage .row1 .col2 {
    width: 100%;
    padding: 0 15px 15px;
    display: block;
  }

  .homepage .row1 .col3 {
    width: 100%;
    padding: 0 15px;
    display: block;
  }

  .homepage .row2 > .inner {
    display: block;
    width: 100%;
  }

  .homepage .row2 .col1 {
    width: 100%;
    padding: 0 15px 15px;
    display: block;
  }

  .homepage .row2 .col2 {
    width: 100%;
    padding: 0 15px;
    display: block;
  }

  .homepage .row3 {
    padding: 0 15px 15px;
  }

  .homepage .row3 > .inner {
    display: block;
    width: 100%;
  }

  .homepage .row3 .col1 {
    width: 100%;
    padding: 0 0 15px;
    display: block;
  }

  .homepage .row3 .col2 {
    width: 100%;
    padding: 0;
    display: block;
  }

  .homepage .row1 .col1 .inner {
    padding: 0 20px;
  }

  .homepage .row1 .col2 .inner {
    padding: 0 20px;
  }

  .homepage .row1 .col3 .inner {
    padding: 0 20px;
  }

  .homepage .row2 .col2 .inner {
    padding: 0 20px;
  }

  .homepage .row3 > .inner {
    padding: 20px;
  }

  .homepage .row3 .col1 {
    border-bottom: 2px solid white;
    border-left: none;
    padding: 20px 0;
  }

  .homepage .row3 .col1 .text {
    padding-top: 20px;
  }

  .homepage .row3 .col2 .inner {
    padding: 20px 0 0;
  }
}
/* ----------------------------------------------- */
.csinput {
  box-sizing: border-box;
  display: block;
  width: 240px;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  text-indent: 10px;
  margin-bottom: 10px;
}

.cstextarea {
  box-sizing: border-box;
  display: block;
  width: 240px;
  height: 140px;
  background: white;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  text-indent: 10px;
  margin-bottom: 10px;
}

.searchResult {
  margin-bottom: 20px;
}

.searchUrl {
  display: none;
}
/* ----------------------------------------------- */

.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}

.col-sm-6 {
  position: relative;
  float: right;
  width: 50%;
}

.img_of_select {
  display: block;
  max-width: 100%;
  max-height: 40px;
  height: auto;
  width: auto;
}

.print_page {
  cursor: pointer;
}
@media all and (max-width: 590px) {
  .col-sm-6 {
    float: none;
    width: 100%;
  }

  .hidden-xs {
    display: none;
  }

  .mobile-table-full {
    display: block;
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  .mobile-table-full {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .mobile-table-full img {
    margin-top: 30px;
    margin-left: 10%;
  }
}

.hidden_label {
  display: block;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
